import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import {
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';
import { LogosContent } from '../../innerComponents';

export const DefaultLayout = (props) => {
  const {
    section: {
      style,
      link,
      text,
      titleAlignment,
      layoutAlignment,
      sponsorsCategories = [],
    },
    category,
    categoryHeading,
    showCallToAction,
  } = props;
  const { organisations = [], logoSize = 'MEDIUM' } = category;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const vStyles = themeConfig.module.vStyles;

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <StyledHeading
            content={categoryHeading}
            displayStyles={dStyles}
            visualStyles={vStyles}
            align={titleAlignment}
          />
          {sponsorsCategories.length === 1 && (
            <StyledParagraph content={text} displayStyles={dStyles} />
          )}
        </div>
      </div>
      <LogosContent
        logoSize={logoSize}
        organisations={organisations}
        style={style}
        layoutAlignment={layoutAlignment}
      />
      {showCallToAction && link?.type && (
        <div className="row">
          <div className="col-xs-12">
            <StyledCallToAction ctaConfig={link} displayStyles={dStyles} />
          </div>
        </div>
      )}
    </div>
  );
};

DefaultLayout.propTypes = {
  section: PropTypes.shape({
    sponsorsCategories: PropTypes.array,
    categoryId: PropTypes.string,
    style: PropTypes.string,
    layout: PropTypes.string,
    layoutAlignment: PropTypes.string,
    link: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    titleAlignment: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  category: PropTypes.object,
  categoryHeading: PropTypes.string,
};
