import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { ThemeContext } from 'styled-components';
import Api from '../../../../../../api/LeadGenService/v1';
import Patterns from '../../../../../../utils/pattern/patterns';
import { ERROR, SUBSCRIBED } from '../../utils/constants';
import { CallToAction } from '../../../sharedComponents';
import { TextField } from '../TextField';
import {
  StyledForm,
  StyledCallToAction,
  PrivacyPolicyText,
} from '../../styles';
import { getStateFieldLabel } from '../../../../../paymentJourney/DelegateDetailsForm/sharedComponents/DistanceLearningForm';
import { Select } from '../Select';
import {
  countriesList,
  statesList,
} from '../../../../../../services/Dictionary';
import { StyledLink } from '../Link';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectSiteHeader } from '../../../../../../store/features/siteHeader';

const EmailOptInForm = (props) => {
  const {
    cookieName,
    cookies,
    sapProductCode,
    buttonText,
    setSubscriptionStatus,
    pageConfig: { cookieSitePath },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.module.vStyles;
  const { data: { title = '' } = {} } = useRootSelector(selectSiteHeader);

  const {
    elements: { callToAction: { settings: { size: ctaSize } = {} } = {} } = {},
  } = themeConfig.module.dStyles.elements.form;

  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = methods;

  const selectedCountry = watch('country');

  const submitHandler = async function (values) {
    // To prevent sending an Email Opt In, we check for filled-up field, as this indicates that the request may not have been sent by a human.
    if (values.company) return;

    setLoading(true);

    const response = await fetch(Api.subscribe('email/event'), {
      method: 'POST',
      headers: {
        'Accept-Language': i18n.language,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...values, productCode: sapProductCode }),
    });
    setLoading(false);

    if (response.status !== 200) {
      setSubscriptionStatus(ERROR);
      return;
    }

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    const acceptedSAPCodes = cookies.get(cookieName) || [];
    const updatedAcceptedSAPCodes = [...acceptedSAPCodes, sapProductCode];

    cookies.set(cookieName, JSON.stringify(updatedAcceptedSAPCodes), {
      path: cookieSitePath,
      expires: expirationDate,
      secure: true,
    });
    setSubscriptionStatus(SUBSCRIBED);
  };

  const countriesOptions = Object.keys(countriesList).map((key) => {
    return {
      value: key,
      label: t(countriesList[key]),
    };
  });

  const statesOptions =
    statesList[selectedCountry] &&
    Object.keys(statesList[selectedCountry]).map((key) => {
      return {
        value: key,
        label: t(statesList[selectedCountry][key]),
      };
    });

  return (
    <FormProvider {...methods}>
      <StyledForm
        name="emailOptInForm"
        role="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        data-testid="email-opt-in-form"
      >
        <div className="row">
          <div className={'col-xs-12 col-md-6'}>
            <TextField
              type="text"
              name="firstName"
              placeholder={t('site.email-opt-in.first-name.placeholder')}
              rules={{
                required: true,
              }}
              hasError={!!errors?.firstName}
            />
          </div>
          <div className={'col-xs-12 col-md-6'}>
            <TextField
              type="text"
              name="lastName"
              placeholder={t('site.email-opt-in.last-name.placeholder')}
              rules={{
                required: true,
              }}
              hasError={!!errors?.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className={'col-xs-12 col-md-6'}>
            <TextField
              type="email"
              name="email"
              placeholder={t('site.email-opt-in.email.placeholder')}
              rules={{
                required: true,
                validate: (value) => Patterns.isEmail(value),
              }}
              hasError={!!errors?.email}
            />
          </div>
          <div className={'col-xs-12 col-md-6'}>
            <Select
              name="country"
              options={countriesOptions}
              placeholder={t('site.email-opt-in.country.placeholder')}
              rules={{
                required: true,
              }}
              hasError={!!errors?.country}
              testId="email-ept-in-country-select"
            />
            {statesList[selectedCountry] && (
              <Select
                name="state"
                options={statesOptions}
                placeholder={t(getStateFieldLabel(selectedCountry))}
                rules={{
                  required: true,
                }}
                hasError={!!errors?.state}
                testId="email-ept-in-state-select"
              />
            )}
            <div hidden>
              <TextField type="text" name="company" placeholder="Company" />
            </div>
          </div>
        </div>
        {isDirty && (
          <>
            <PrivacyPolicyText
              visualStyles={vStyles}
              data-testid={'email-opt-in-privacy-policy'}
            >
              {t('site.email-opt-in.privacy-policy.definition', {
                buttonText,
                siteName: title,
              })}
            </PrivacyPolicyText>
            <PrivacyPolicyText visualStyles={vStyles}>
              <Trans
                i18nKey="site.email-opt-in.privacy-policy.explanation"
                values={{
                  privacy: t('site.email-opt-in.privacy-policy'),
                }}
              >
                <StyledLink href={'https://www.informa.com/privacy-policy/'}>
                  {t('site.email-opt-in.privacy-policy')}
                </StyledLink>
              </Trans>
            </PrivacyPolicyText>
          </>
        )}
        <StyledCallToAction>
          <CallToAction
            type="submit"
            size={ctaSize}
            label={buttonText}
            loading={loading}
            className="c-email-opt-in-submit"
            testId="cta-submit-subscription"
          />
        </StyledCallToAction>
      </StyledForm>
    </FormProvider>
  );
};

EmailOptInForm.propTypes = {
  cookieName: PropTypes.string,
  sapProductCode: PropTypes.string,
  buttonText: PropTypes.string,
  setSubscriptionStatus: PropTypes.func,
  pageConfig: PropTypes.shape({
    cookieSitePath: PropTypes.string,
  }),
  cookies: PropTypes.instanceOf(Cookies),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(withCookies(EmailOptInForm));
